<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="false"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex px-3 py-3 flex-column">
          <div v-if="isLoader">
            <ion-skeleton-text animated class="my-1" style="width: 250px; height: 18px" />
          </div>
          <div v-else>
            <div class="d-flex justify-content-between">
              <div class="title">
                {{
                  isSubmitting
                    ? 'Check your answers and click submit to get insights!'
                    : get(quizData, 'is_char_based')
                    ? ` Answer ${totalQuesNo} questions to learn about your OC!`
                    : ` Answer ${totalQuesNo} questions to get insights`
                }}
              </div>
              <div></div>
              <ion-buttons slot="end">
                <ion-button class="close-button" @click="dismissModal()">
                  <i class="ti-close" />
                </ion-button>
              </ion-buttons>
            </div>

            <div class="d-flex total-ques mt-1" :style="isSubmitting ? 'color:#ff9c33' : 'color:#00b4c5'">
              <div class="d-flex align-items-center ques-text">Question {{ currentQuesNo }}/{{ totalQuesNo }}</div>

              <div class="px-1 py-1 mx-1 ques-bar">
                <div class="d-flex" style="gap: 2px">
                  <div
                    v-for="(ques, index) in quizQuestions"
                    :key="index"
                    class="selected-ans-bar"
                    :class="{
                      'bar-color-changed': !isEmpty(get(ques, 'option_selected.text')) && !isSubmitting,
                      'submitted-bar-clr': isSubmitting,
                    }"
                  ></div>

                  <!-- <div class="selected-ans-bar mx-1"></div>
                <div class="selected-ans-bar"></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content quizzes-container">
      <div v-if="isLoader" class="d-flex justify-content-center align-items-center">
        <ChLoading size="lg" class="spinner" />
      </div>
      <div v-else>
        <div v-if="!isSubmitting" class="pb-3 px-3 mt-3">
          <div class="d-flex mb-3">
            <div>{{ currentQuesNo }}.</div>

            <div class="px-1">
              {{ currQues }}
            </div>
          </div>

          <div
            v-for="ans of currQuesOptions"
            :key="`ans-${ans.id}`"
            class="ans-border py-2 px-2 mt-2 clickable-item-hov"
            :class="checkSelAns(ans)"
            @click="selectedAnswer(ans)"
          >
            <span> {{ ans.text }}</span>
          </div>
        </div>

        <div v-else class="pb-3 px-3 mt-3">
          <div v-if="get(quizData, 'is_char_based')" class="d-flex">
            <div style="height: 20px; width: 20px; border-radius: 20px">
              <img loading="lazy" v-character-image :src="get(char, 'info.cropProfilePicture')" />
            </div>
            <div class="mx-1">
              {{ get(char, 'info.name') }}
            </div>
          </div>
          <div v-for="(ques, index) of quizQuestions" :key="ques.id" class="py-2" @click="fetchQuesAns(index)">
            <div>
              <strong>Question {{ index + 1 }}</strong>
            </div>
            <div class="ans-border selected-ans-border py-2 px-2 mt-2 clickable-item-hov">
              {{ get(ques, 'option_selected.text') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <ion-button class="arrow-btn" :disabled="currentQuesNo <= 1 && !isSubmitting" @click="nextprevQues(-1)">
            <span> <i class="ti-arrow-left icon" /></span>
          </ion-button>
          <ChButton
            class="choose-btn"
            :class="{ 'submit-btn': isSubmitting }"
            :disabled="isEmpty(selectedAnsIndex) && !isSubmitting"
            :loading="loading"
            :showSuccess="quizData?.quiz_data?.total_days !== quizData?.quiz_data?.next_day"
            :showBigSuccess="quizData?.quiz_data?.total_days === quizData?.quiz_data?.next_day"
            bigSuccessMessage="Congratulations, you finished the quiz."
            @click="actionClicked"
          >
            {{ isSubmitting ? 'Submit' : 'Choose' }}
          </ChButton>
          <ion-button
            class="arrow-btn"
            :disabled="isEmpty(selectedAnsIndex) || currentQuesNo === totalQuesNo"
            @click="nextprevQues(1)"
          >
            <span> <i class="ti-arrow-right icon" /></span>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { postQuizzes } from '@/shared/actions/quizzes';

import ChButton from '@/shared/components/ChButton.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },

  char: {
    type: Object,
    default: () => {},
  },
  quiz: {
    type: Object,
    default: () => {},
  },
  quizData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(['close', 'selected']);
const char = toRef(props, 'char');
const isOpen = toRef(props, 'isOpen');
const quizData = toRef(props, 'quizData');
const quiz = toRef(props, 'quiz');
const selectedAnsIndex: any = ref(null);
const selectedQuesAnsIndex: any = ref(null);
const currentQuesNo: any = ref(1);
const isSubmitting = ref(false);
const loading = ref(false);
const isLoader = ref(true);
const document = useDocument();

const showCrisp = () => {
  document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'auto';
  document.value.getElementsByTagName('html')[0].style.touchAction = 'auto';

  (window as any).$crisp?.push(['do', 'chat:show']);
  const el = document.value?.querySelector('.crisp-client') as any;
  if (el) el.style.display = 'block';
};
const dismissModal = () => {
  showCrisp();
  emits('close');
  resetData();
};

watch(isOpen, () => {
  if (isOpen.value) {
    document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'none';
    document.value.getElementsByTagName('html')[0].style.touchAction = 'none';

    (window as any).$crisp?.push(['do', 'chat:hide']);
    const el = document.value?.querySelector('.crisp-client') as any;
    if (el) el.style.display = 'none';
    isLoader.value = true;
    currentQuesNo.value = 1;
    isLoader.value = false;
  }
});

const currQues = computed(() => {
  if (!isEmpty(quizQuestions.value)) {
    return get(quizQuestions.value[currQuesIndex.value], 'que');
  }
});

const selectedOp = computed(() => {
  if (!isEmpty(quizData.value)) {
    return quizQuestions.value[currQuesIndex.value].option_selected;
  }
});

const checkSelAns = (ans: any) => {
  return selectedAnsIndex.value === ans.id || get(selectedOp.value, 'id') === ans.id
    ? 'selected-ans-border'
    : selectedAnsIndex.value === null
    ? ''
    : 'not-selected-border';
};

const totalQuesNo = computed(() => {
  if (!isEmpty(quizQuestions.value)) {
    return quizQuestions.value.length;
  }
});

const currQuesIndex = computed(() => {
  return selectedQuesAnsIndex.value !== null ? selectedQuesAnsIndex.value : currentQuesNo.value - 1;
});
const currQuesOptions = computed(() => {
  if (!isEmpty(quizQuestions.value)) {
    return quizQuestions.value[currQuesIndex.value].options;
  }
});
const resetData = () => {
  isSubmitting.value = false;
  selectedAnsIndex.value = null;
  selectedQuesAnsIndex.value = null;
  currentQuesNo.value = 1;
};
const actionClicked = async () => {
  if (!isSubmitting.value) {
    nextprevQues(1);
  } else {
    loading.value = true;
    const qResp = quizQuestions.value.map((item: any) => ({
      id: item.id,
      question: item.que,
      option_selected: item.option_selected || null,
    }));
    const payload = {
      character: get(quizData.value, 'is_char_based') ? get(char.value, 'id', null) : null,
      quiz: quizData.value.id,
      extra: {
        title: quizData.value.quiz_data.title,
        quiz_response: qResp,
      },
    };

    const resp = await postQuizzes(payload, get(quizData.value, 'title'), get(quizData.value, 'quiz_data.next_day'));
    loading.value = false;
    showCrisp();
    setTimeout(() => {
      emits('selected', resp.id);
      resetData();
    }, 2000);
  }
};

const fetchQuesAns = (index: any) => {
  selectedQuesAnsIndex.value = index;
  currentQuesNo.value = selectedQuesAnsIndex.value + 1;
  isSubmitting.value = false;
};
const selectedAnswer = (answer: any) => {
  selectedAnsIndex.value = answer.id;
  quizData.value.quiz_data.questions[currQuesIndex.value] = {
    ...quizData.value.quiz_data.questions[currQuesIndex.value],
    option_selected: answer,
  };
};
const quizQuestions = computed(() => {
  if (!isEmpty(quizData.value)) {
    return get(quizData.value, 'quiz_data.questions');
  }
});

const nextprevQues = (currentDirection: any) => {
  selectedAnsIndex.value = null;
  if (isSubmitting.value && currentDirection === -1) {
    isSubmitting.value = false;
    currentQuesNo.value = totalQuesNo.value;
  } else {
    const canIncrement = currentQuesNo.value < totalQuesNo.value && currentDirection === 1;
    const canDecrement = currentQuesNo.value > 1 && currentDirection === -1;
    if (canIncrement || canDecrement) {
      if (selectedQuesAnsIndex.value !== null) {
        selectedQuesAnsIndex.value = canIncrement ? selectedQuesAnsIndex.value + 1 : selectedQuesAnsIndex.value - 1;
      }
      currentQuesNo.value = canIncrement ? currentQuesNo.value + 1 : currentQuesNo.value - 1;
    } else {
      isSubmitting.value = true;
    }
  }
  if (!isEmpty(selectedOp.value)) {
    selectedAnsIndex.value = quizQuestions.value[currQuesIndex.value].id;
  }
  nextTick(() => {
    document.value?.querySelector('.quizzes-container')?.scrollTo({ top: 0, behavior: 'smooth' });
  });
};
</script>
<style lang="sass" scoped>
.ques-text
  font-weight: bold
  @media(max-width:360px)
    font-size: 14px
.ques-bar
  border: 2px solid
  border-radius: 20px
  height: 24px
  width: 100%
.spinner
  width: 30px
  height: 30px
.submit-btn
  ::v-deep
    ion-button
      --background: #ff9c33 !important
.arrow-btn
  width: 46px
  height: 31px
  --border-radius: 8px
  --background: #00b4c5
.dark .arrow-btn::part(native)
  color: white !important
.choose-btn
  --border-radius: 8px
  --background: #00b4c5
  text-transform: none
  color: white !important
.choose-btn::part(native)
  color: white !important
.bar-color-changed
  background: #00b4c5 !important
.submitted-bar-clr
  background: #ff9c33 !important
.selected-ans-bar
  background: #e6e6e6
  width: 100%
  border-radius: 20px
  padding-bottom: 0.4rem
  padding-top: 0.4rem
.selected-ans-border
  border: 2px solid var(--ion-color-primary) !important
  color: var(--ion-color-primary)
  font-weight: 800
.not-selected-border
  opacity: 0.5
.dark .changed-border
  color: var(--ion-color-primary) !important
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: calc(85dvh - 45px)
  overflow: auto
  overflow-x: hidden
  @media(max-width:550px)
    max-height: calc(85dvh - 210px)
.total-ques
  white-space: nowrap
.dark .toolbar
  --background: #17074c !important
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  @media(max-width:476px)
    padding-bottom: 18px !important
.sub-title
  font-size: 16px
  font-weight: bold
.title
  color: #214163
  font-size: 20px
  font-weight: bold
.ans-border
  border: 2px solid gray
  border-radius: 7px
  margin-left: 1rem
</style>
