export const useBigConfetti = () => {
  const canvasEl = ref<any>(null);
  const rendererID = ref<number>();

  const clear = () => {
    const { ctx } = init();

    ctx.clearRect(0, 0, ctx.width, ctx.height);

    window.cancelAnimationFrame(rendererID.value || 0);
  };

  const init = () => {
    if (!canvasEl.value) {
      canvasEl.value = document.getElementById('animations');

      if (!canvasEl.value) return { cx: 0, cy: 0, ctx: undefined };
    }

    const ctx = canvasEl.value.getContext('2d');
    canvasEl.value.width = window.innerWidth;
    canvasEl.value.height = window.innerHeight;
    const cx = ctx.canvas.width / 2;
    const cy = ctx.canvas.height / 2;

    return { cx, cy, ctx };
  };

  const confetti: any[] = [];
  const confettiCount = 300;
  const gravity = 0.5;
  const terminalVelocity = 5;
  const drag = 0.075;
  const colors = [
    { front: 'red', back: 'darkred' },
    { front: 'green', back: 'darkgreen' },
    { front: 'blue', back: 'darkblue' },
    { front: 'yellow', back: 'darkyellow' },
    { front: 'orange', back: 'darkorange' },
    { front: 'pink', back: 'darkpink' },
    { front: 'purple', back: 'darkpurple' },
    { front: 'turquoise', back: 'darkturquoise' },
  ];

  const resizeCanvas = () => init();

  const randomRange = (min: number, max: number) => Math.random() * (max - min) + min;

  const initBurst = () => {
    init();

    for (let i = 0; i < confettiCount; i++) {
      confetti.push({
        color: colors[Math.floor(randomRange(0, colors.length))],
        dimensions: {
          x: randomRange(10, 20),
          y: randomRange(10, 30),
        },
        position: {
          x: randomRange(0, canvasEl.value.width),
          y: canvasEl.value.height - 1,
        },
        rotation: randomRange(0, 2 * Math.PI),
        scale: {
          x: 1,
          y: 1,
        },
        velocity: {
          x: randomRange(-25, 25),
          y: randomRange(0, -50),
        },
      });
    }
  };

  const render = () => {
    const { ctx } = init();

    ctx.clearRect(0, 0, canvasEl.value.width, canvasEl.value.height);

    confetti.forEach((confetto, index) => {
      let width = confetto.dimensions.x * confetto.scale.x;
      let height = confetto.dimensions.y * confetto.scale.y;

      ctx.translate(confetto.position.x, confetto.position.y);
      ctx.rotate(confetto.rotation);

      confetto.velocity.x -= confetto.velocity.x * drag;
      confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity);
      confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();
      confetto.position.x += confetto.velocity.x;
      confetto.position.y += confetto.velocity.y;

      if (confetto.position.y >= canvasEl.value.height) confetti.splice(index, 1);
      if (confetto.position.x > canvasEl.value.width) confetto.position.x = 0;
      if (confetto.position.x < 0) confetto.position.x = canvasEl.value.width;

      confetto.scale.y = Math.cos(confetto.position.y * 0.1);
      ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

      ctx.fillRect(-width / 2, -height / 2, width, height);
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    });

    window.requestAnimationFrame(render);
  };

  return { initBurst, resizeCanvas, render, canvasEl, clear };
};
