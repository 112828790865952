<template>
  <ion-card class="quizzes-card clickable-item" :class="setWidth ? 'set-width' : 'mx-0'" @click="openModal">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center text-center justify-content-center title-bg">
        <div class="d-flex">
          <ion-badge
            class="badge"
            :class="{ 'badge-width': (get(quiz, 'quiz.category') || get(quiz, 'category')) === 'wb' }"
            >{{
              isContinue ? categoriesByVal[get(quiz, 'quiz.category')].name : categoriesByVal[quiz.category].name
            }}</ion-badge
          >
          <ion-badge class="ques-badge">
            <span class="mx-1">{{
              isContinue && get(quiz, 'quiz.question_count') <= 99
                ? get(quiz, 'quiz.question_count')
                : isContinue && get(quiz, 'quiz.question_count') > 99
                ? ` ${get(quiz, 'quiz.question_count')}+`
                : !isContinue && quiz.question_count <= 99
                ? quiz.question_count
                : `${quiz.question_count}+`
            }}</span>
            <ion-icon class="chat-icon" :icon="chatbubblesOutline" />
          </ion-badge>
        </div>
        <div class="mt-4 title px-2">
          {{
            isContinue
              ? truncateText(get(quiz, 'quiz.title'), isMobSmallScreen ? 50 : 43)
              : truncateText(quiz.title, isMobSmallScreen ? 50 : 43)
          }}
        </div>
      </div>
      <div class="mt-auto d-flex flex-column justify-content-center align-items-center text-center card-btm">
        <div v-if="isContinue">
          <div v-if="get(quiz, 'quiz.is_char_based')" class="d-flex">
            <img
              v-character-image
              class="mx-1 char-img"
              :class="{ 'set-margin': getCurrentDateTime < getNextDayDateTime }"
              :src="get(quiz.character, 'info.cropProfilePicture')"
            />

            <div class="d-flex align-items-center name">
              <router-link
                v-if="get(quiz, 'character.slug')"
                target="_blank"
                :to="{ name: 'character-profile-new', params: { slug: get(quiz, 'character.slug') } }"
                class="clickable text-black"
              >
                {{ truncateText(get(quiz.character, 'info.name'), 15) }}
              </router-link>
            </div>
          </div>
          <img
            loading="lazy"
            v-else
            v-user-image
            class="mx-1 char-img"
            :src="resizeUpload(user.profile_picture_url, '80x80')"
          />
        </div>

        <div :class="{ 'w-50': !isContinue }">
          <ion-button
            v-if="!isContinue"
            class="day-btn w-100"
            style="--background: linear-gradient(90deg, #ae38e5 0%, rgba(174, 56, 229, 0) 100%), #7050b7; height: 37px"
            @click.stop="openQuizzesModal"
          >
            Start
          </ion-button>

          <vue-countdown
            v-else
            v-slot="{ hours, minutes, seconds }"
            :time="formatFromNowInMilliseconds(quiz.next_day_start_date)"
          >
            <div v-if="getTimeFormat">
              <ion-button class="day-btn" style="color: black" @click="continueQuizModal"
                >Day {{ get(quiz, 'next_day') }} is ready!
              </ion-button>
            </div>
            <div v-else class="time-border">
              <div class="d-flex justify-content-center text-center align-items-center flex-column">
                <div class="d-flex">
                  <ion-icon class="icon mr-1" :icon="timeOutline" />
                  <div class="unlock">Next day unlocks in:</div>
                </div>

                <div class="pl-1 time">
                  <span v-if="hours">{{ hours }}h</span>
                  <span v-if="minutes" class="mx-1"> {{ minutes < 10 ? `0${minutes}` : minutes }}m</span>
                  <span v-if="!hours">{{ seconds < 10 ? `0${seconds}` : seconds }}s</span>
                </div>
              </div>
            </div>
          </vue-countdown>
        </div>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts" setup>
import { timeOutline, chatbubblesOutline } from 'ionicons/icons';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';

const props = defineProps({
  quiz: {
    type: Object,
    default: () => {},
  },
  isContinue: {
    type: Boolean,
    default: false,
  },
  counts: {
    type: Object,
    default: () => {},
  },
  setWidth: {
    type: Boolean,
    default: false,
  },
});
const categoriesByVal = keyBy(constants.quizCategories, 'value');
const quiz = toRef(props, 'quiz');
const isContinue = toRef(props, 'isContinue');
const router = useRouter();
const emits = defineEmits(['open', 'continue']);

const currentTime = ref(new Date());

const updateTime = () => {
  currentTime.value = new Date();
};

const getNextDayDateTime = computed(() => {
  const { getDateTime } = useCurrentTime(new Date(quiz.value.next_day_start_date));
  return getDateTime.value;
});

const getCurrentDateTime = computed(() => {
  const { getDateTime } = useCurrentTime(currentTime.value);
  return getDateTime.value;
});
const timeInterval = setInterval(updateTime, 1000);

const openQuizzesModal = () => {
  router.push({ name: 'quizzes', query: { quizId: quiz.value.id } });

  setTimeout(() => {
    emits('open');
  }, 10);
};

const continueQuizModal = () => {
  const isContinueInsight = getCurrentDateTime.value < getNextDayDateTime.value;
  if (!isContinueInsight)
    router.push({
      name: 'quizzes',
      query: { quizId: quiz.value.quiz.id, logId: quiz.value.id, charId: get(quiz.value, 'character.id') },
    });
  setTimeout(() => {
    emits('continue', isContinueInsight, quiz.value);
  }, 10);
};

const getTimeFormat = computed(() => {
  if (currentTime.value && get(quiz.value, 'next_day_start_date')) {
    return getCurrentDateTime.value >= getNextDayDateTime.value;
  }
});

const openModal = () => {
  isContinue.value ? continueQuizModal() : openQuizzesModal();
};

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});
const getBadgeColor = computed(() => {
  return isContinue.value
    ? categoriesByVal[get(quiz.value, 'quiz.category')].color
    : categoriesByVal[quiz.value.category].color;
});
const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 500;
});

onUnmounted(() => {
  clearInterval(timeInterval);
});
</script>

<style lang="sass" scoped>
.set-width
  width: 185px !important
  margin-left: 1px
  margin-right: 5px
  min-width: 185px !important
  margin-bottom: 0px !important
.title-bg
  background: #eaecf6
  border: 2px solid white
  height: 125px
  border-top-left-radius: 10px
  border-top-right-radius: 10px
.title
  color: #214163
  font-size: 18px
  font-weight: bold
.dark .title
  color: white
.dark .title-bg
  background: #17074c !important
  border: none !important
.card-btm
  height: 81px

.unlock
  color: #214163
  font-size: 12px
  margin-top: 1px
  @media(max-width:880px)
    text-wrap: nowrap
    font-size: 13px
.dark .unlock
  color: white !important

.char-img
  width: 25px !important
  height: 25px !important
  border-radius: 13px !important
.set-margin
  margin-bottom: 2px
.time-border
  border-radius: 10px
  border: 2px solid #214163
  padding-top: 1px
  padding-bottom: 1px
  padding-left: 0.30rem
  padding-right: 0.30rem
  @media(max-width:1184px) and (min-width:590px)
    padding-left: 0.5rem
    padding-right: 0.5rem

  @media(max-width:590px) and (min-width:569px)
    padding-left: 0px !important
    padding-right: 0px !important

.time
  font-weight: bolder
  color: var(--ion-color-primary)
  font-size: 11px

.icon
  font-size: 16px
  color: #214163
.dark .icon
  color: white !important
.badge-width
  @media(max-width:1184px)
    min-width: 107px !important
.badge
  top: 6px
  position: absolute
  left: 6px
  line-height: 14px
  background: #011c38
  border-radius: 10px
  max-width: 100%
  min-width: 41px
  color: v-bind(getBadgeColor)
  border: 2px solid v-bind(getBadgeColor)
  height: 22px

.chat-icon
  font-size: 12px
  color: white
.ques-badge
  top: 6px
  position: absolute
  right: 8px
  background: #011c38
  border-radius: 10px
  max-width: 100%
  min-width: 41px
  height: 20px

.day-btn
  --background: linear-gradient(90deg, #AE38E5 0%, rgba(174, 56, 229, 0) 100%), #7050B7
  --border-radius: 20px
  color: white !important
  font-weight: bold
  text-transform: none
  height: 34px
.name
  color: #214163
  overflow-wrap: anywhere
.dark .name
  color: white !important
.quizzes-card
  width: 100%
  margin-bottom: -4px
  img
    width: 100%
    height: 100%
    border-top-left-radius: 10px
    border-top-right-radius: 10px
    object-fit: cover
</style>
