<template>
  <ion-page class="page bg-transparent">
    <div class="d-flex py-3">
      <div class="title">Take Quizzes as your character</div>
    </div>

    <div class="d-flex justify-content-between flex-wrap px-3 quiz-bar" :class="userLoader ? 'py-2' : 'pt-3'">
      <div class="d-flex">
        <img loading="lazy" v-user-image class="user-prof" :src="user.profile_picture_url" />
        <div class="px-2 py-2">
          <router-link :to="{ name: 'profile', params: { username: user.username } }" target="_blank">
            {{ user.username }}</router-link
          >
        </div>
      </div>
      <div></div>

      <div v-if="userLoader" class="d-flex align-items-center text-center">
        <ion-skeleton-text :animated="true" class="token-field ml-1" />
      </div>
      <div v-else class="d-flex ml-auto clickable-item-hov">
        <div style="position: relative">
          <inline-svg src="/ticket-with-border.svg" class="ticket-svg" @click="openPopup($event)" />
        </div>
        <div
          class="d-flex align-items-center text-center justify-content-center px-3 ticket-badge"
          @click="openPopup($event)"
        >
          <div class="px-2 count-badge" :class="{ 'mx-1': !isEmpty(userCounts.time_difference) }">
            <span style="font-size: 20px" v-if="isUltraSubscription">∞</span>
            <template v-else>
              <span style="font-size: 20px">{{ userCounts.quiz_count_available }}</span
              >/{{ userCounts.max_allowed_quiz }}
            </template>
          </div>
          <vue-countdown
            v-if="
              !isUltraSubscription &&
              !isEmpty(userCounts.time_difference) &&
              userCounts.quiz_count_available < userCounts.max_allowed_quiz
            "
            v-slot="{ hours, minutes, seconds }"
            :time="formatFromNowInMilliseconds(userCounts.time_difference)"
            class="d-flex ml-1"
            @end="getCounts"
          >
            <span v-if="hours">{{ hours < 10 ? `0${hours}` : hours }}h</span>
            <span v-if="hours && minutes" style="margin: 2px" />
            <span v-if="minutes"> {{ minutes < 10 ? `0${minutes}` : minutes }}m</span>
            <span v-if="!hours && minutes" style="margin: 2px" />
            <span v-if="!hours">{{ seconds < 10 ? `0${seconds}` : seconds }}s</span>
          </vue-countdown>
        </div>
      </div>
    </div>
    <div class="d-flex py-3 ml-auto">
      <div v-if="isAuthenticated" class="quiz-history clickable-item-hov">
        <router-link :to="{ name: 'quizzes-history' }"> Your Quiz History </router-link>
      </div>
    </div>
    <quizzes-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />

    <horizontal-quizzes-card
      v-if="!isEmpty(quizzesLogs)"
      :is-continue="true"
      section-title="Continue Quiz"
      :quizzes="quizzesLogs"
      :counts="userCounts"
      :loading="isLoading"
      :is-quiz-loader="isQuizLoader"
      :next-page-exists="contQuizzesNextPage"
      @load-more="loadMoreContQuizzes"
      @open="fetchQuizDetails"
      @continue="fetchQuizDetails"
    />
    <horizontal-quizzes-card
      :counts="userCounts"
      section-title="Popular Quizzes"
      :next-page-exists="false"
      :loading="false"
      :is-quiz-loader="isQuizLoader"
      :quizzes="popularQuizzes"
      @open="fetchQuizDetails"
    />
    <horizontal-quizzes-card
      :counts="userCounts"
      section-title="New Quizzes"
      :next-page-exists="newQuizzesNextPage"
      :quizzes="quizzes"
      :is-new="true"
      :loading="isNewLoading"
      :is-quiz-loader="isQuizLoader"
      @load-more="loadMoreNewQuizzes"
      @open="fetchQuizDetails"
    />

    <quiz-character-selection-modal
      :is-open="isOpenQuizzesModal"
      :quiz="quizData"
      @close="closeModal"
      @selected="closeAndOpenQuesAnsModal"
      @continue="closeAndOpenContinueModal"
    />
    <quizzes-ques-and-ans-modal
      :is-open="isOpenQuizQuesAnsModal"
      :char="selectedChar"
      :quiz-id="quizId"
      :quiz-data="quizData"
      @close="closeQuizQuesAndAnsModal"
      @selected="closeAndopenInsightsModal"
    />
    <quiz-insights-modal
      :is-open="isOpenInsightsModal"
      :log-id="logId"
      @close="closeInsightsModal"
      @view="closeAndOpenViewInsightModal"
    />
    <view-insight-report-modal
      :insights="insight"
      :insight-index="insightIndex"
      :is-open="isOpenViewInsightModal"
      @close="closeViewInsightModal"
    />

    <no-more-quiz-tokens-modal :counts="userCounts" :is-open="isOpenTokenModal" @close="closeQuizTokenModal" />
  </ion-page>
</template>

<script lang="ts" setup>
import QuizzesTabs from './components/quizzes-tabs.vue';
import HorizontalQuizzesCard from './components/HorizontalQuizzesCard.vue';
import QuizCountPopover from './popovers/quiz-count-popover.vue';
import { Paging, Tab } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { quizzesTabs } from '@/shared/statics/tabs';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import QuizCharacterSelectionModal from '@/shared/modals/QuizCharacterSelectionModal.vue';
import QuizzesQuesAndAnsModal from '@/shared/modals/QuizzesQuesAndAnsModal.vue';
import QuizInsightsModal from '@/shared/modals/QuizInsightsModal.vue';
import ViewInsightReportModal from '@/shared/modals/ViewInsightReportModal.vue';
import NoMoreQuizTokensModal from '@/shared/modals/NoMoreQuizTokensModal.vue';
import { getQuizDetails, getQuizzes, getUserQuizCountDetails, getUsersQuizHistory } from '@/shared/actions/quizzes';
import { popovers } from '@/shared/native';
import { getCharacterByIdFull } from '@/shared/actions/characters';
import constants from '@/shared/statics/constants';

const currentTab = ref('all');
const tabs = ref(quizzesTabs);
const tabIndex = ref(0);
const isOpenViewInsightModal = ref(false);
const quizzes = ref([]);
const popularQuizzes = ref([]);
const quizzesLogs: any = ref([]);
const userCounts: any = ref({});
const isOpenQuizzesModal = ref(false);
const isOpenQuizQuesAnsModal = ref(false);
const isOpenTokenModal = ref(false);
const router = useRouter();
const isQuizLoader = ref(false);
const route = useRoute();
const userLoader = ref(false);
const selectedChar: any = ref({});
const insight = ref({});
const quizId = ref('');
const logId = ref('');
const isOpenInsightsModal = ref(false);
const quizData: any = ref({});
const insightIndex = ref('');
const contQUizzesPaging: any = ref<Paging>();
const newQuizzesPaging: any = ref<Paging>();
const contPage = ref(1);
const newPage = ref(1);
const isLoading = ref(false);
const isNewLoading = ref(false);
const { isScrollAtBottom, updateScrollHandleAtBottom } = authStore();

watch(isScrollAtBottom, async (value: boolean) => {
  if (value && newQuizzesNextPage.value && !isNewLoading.value) {
    await debouncedLoadMoreNewQuizzes();
  }
  updateScrollHandleAtBottom(false);
});

onBeforeMount(() => {
  updateScrollHandleAtBottom(false);
});

const isAuthenticated = computed(() => {
  const { isAuthenticated } = authStore();
  return isAuthenticated.value;
});

const categoryVal = computed(() => {
  const val = currentTab.value === 'worldbuilding' ? 'wb' : currentTab.value === 'all' ? '' : currentTab.value;
  return val;
});
const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  contPage.value = 1;
  contQUizzesPaging.value = null;
  fetchQuizzesLog();
  fetchQuizzes();
  fetchPopularQuizzes();
};

const fetchQuizzes = async (offset = 1) => {
  if (offset === 1) isQuizLoader.value = true;
  const { results, ...rest } = !isEmpty(categoryVal.value)
    ? await getQuizzes({ category: categoryVal.value, page: offset, page_size: 36 })
    : await getQuizzes({ page: offset, page_size: 36 });
  quizzes.value = offset === 1 ? results : quizzes.value.concat(results);
  newPage.value = offset;
  newQuizzesPaging.value = rest;
  if (offset === 1) isQuizLoader.value = false;
};

const fetchQuizzesLog = async (offset = 1) => {
  const { results, ...rest } = !isEmpty(categoryVal.value)
    ? await getUsersQuizHistory(offset, 40, {
        ordering: 'next_day_start_date',
        status: 'in_progress',
        quiz__category: categoryVal.value,
      })
    : await getUsersQuizHistory(offset, 40, { ordering: 'next_day_start_date', status: 'in_progress' });

  quizzesLogs.value = offset === 1 ? results : quizzesLogs.value.concat(results);
  contPage.value = offset;
  contQUizzesPaging.value = rest;
};

const fetchPopularQuizzes = async () => {
  const resp = !isEmpty(categoryVal.value)
    ? await getQuizzes({ category: categoryVal.value, ordering: `-avg_submission_day`, page: 1, page_size: 18 })
    : await getQuizzes({ ordering: `-avg_submission_day`, page: 1, page_size: 18 });
  popularQuizzes.value = resp.results;
};

const loadMoreContQuizzes = async () => {
  if (contQuizzesNextPage.value) {
    isLoading.value = true;

    await fetchQuizzesLog(contPage.value + 1);
    isLoading.value = false;
  }
};
const loadMoreNewQuizzes = async () => {
  if (newQuizzesNextPage.value) {
    isNewLoading.value = true;
    await fetchQuizzes(newPage.value + 1);
    isNewLoading.value = false;
  }
};

const debouncedLoadMoreNewQuizzes = debounce(loadMoreNewQuizzes, 200);
const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const fetchCounts = async () => {
  userLoader.value = true;
  await getCounts();
  userLoader.value = false;
};

const getCounts = async () => {
  userCounts.value = await getUserQuizCountDetails();
};

const queryQuizId = computed(() => {
  if (route.query.quizId) {
    return route.query.quizId;
  }
});
const isUltraSubscription = computed(() => {
  return get(user.value, 'subscription_level') == constants.subscriptionLevels.ultra;
});

const openPopup = async (ev: any) => {
  const { subscriptionLevel } = authStore();
  if (subscriptionLevel.value === 2) return;
  await popovers.open(ev, QuizCountPopover);
};

const openQuizModal = (isContinueInsight: boolean, quiz: any) => {
  if (isContinueInsight) {
    logId.value = quiz.id;
    isOpenInsightsModal.value = true;
  } else if (userCounts.value.quiz_count_available > 0) {
    if (get(quizData.value, 'is_char_based') && isEmpty(routeQueryLogId.value)) {
      isOpenQuizzesModal.value = true;
    } else {
      isOpenQuizQuesAnsModal.value = true;
      route.query.logId
        ? router.replace({
            name: 'quizzes',
            query: { quizId: queryQuizId.value, logId: routeQueryLogId.value, charId: routeCharId.value },
          })
        : router.replace({
            name: 'quizzes',
            query: { quizId: queryQuizId.value },
          });
    }
  } else {
    isOpenTokenModal.value = true;
  }
};

const closeModal = (val?: any) => {
  isOpenQuizzesModal.value = false;
  if (get(val, 'detail.role') === 'backdrop') {
    closeModalRoute();
  }
};

const closeModalRoute = () => {
  router.push({ name: 'quizzes' });
};
const closeQuizQuesAndAnsModal = () => {
  isOpenQuizQuesAnsModal.value = false;
  closeModalRoute();
};

const closeSelectionModal = () => {
  isOpenQuizzesModal.value = false;
};

const closeAndOpenQuesAnsModal = (char: any) => {
  selectedChar.value = char;
  closeSelectionModal();
  isOpenQuizQuesAnsModal.value = true;
};

const closeAndOpenContinueModal = async (quiz: any) => {
  const tempQuiz = quiz;
  closeSelectionModal();
  const resp = await getQuizDetails(tempQuiz.quizzes[0].quiz as string, { log_id: tempQuiz.quizzes[0].id });
  quizData.value = resp;
  shuffleQuestionsOptions();
  fetchCharacterDetails();
  openQuizModal(false, '');
};

const closeAndopenInsightsModal = (logVal: any) => {
  logId.value = logVal;
  closeQuizQuesAndAnsModal();
  isOpenInsightsModal.value = true;
  fetchCounts();
  fetchQuizzesLog();
};
const closeInsightsModal = () => {
  isOpenInsightsModal.value = false;
};
const contQuizzesNextPage = computed(() => {
  return !!contQUizzesPaging.value?.next;
});

const newQuizzesNextPage = computed(() => {
  return !!newQuizzesPaging.value?.next;
});
const closeViewInsightModal = () => {
  isOpenViewInsightModal.value = false;
  fetchCounts();
  fetchQuizzesLog();
};
const closeAndOpenViewInsightModal = (val: any, index: any) => {
  insight.value = val;
  insightIndex.value = index;
  closeInsightsModal();
  isOpenViewInsightModal.value = true;
  fetchCounts();
  fetchQuizzesLog();
};

const closeQuizTokenModal = () => {
  isOpenTokenModal.value = false;
  closeModalRoute();
};
const routeQueryLogId = computed(() => {
  if (route.query.logId) {
    return get(route.query, 'logId');
  }
});

const routeCharId = computed(() => {
  if (route.query.charId) {
    return get(route.query, 'charId');
  }
});

const shuffleQuestionsOptions = () => {
  const quizQuestions: any = quizData.value.quiz_data.questions;
  for (const question of quizQuestions) {
    question.options = shuffle(question.options);
  }
};

const fetchCharacterDetails = async () => {
  if (routeQueryLogId.value && routeCharId.value) {
    const resp2 = await getCharacterByIdFull(routeCharId.value as string);
    selectedChar.value = resp2;
  }
};

const fetchQuizDetails = async (isContinueInsight: boolean, quiz: any) => {
  quizData.value = {};
  if (!isContinueInsight) {
    const resp = routeQueryLogId.value
      ? await getQuizDetails(queryQuizId.value as string, { log_id: routeQueryLogId.value as string })
      : await getQuizDetails(queryQuizId.value as string);
    quizData.value = resp;
    shuffleQuestionsOptions();
    fetchCharacterDetails();
  }

  openQuizModal(isContinueInsight, quiz);
};

onMounted(async () => {
  await fetchCounts();
  fetchQuizzes();
  fetchQuizzesLog();
  fetchPopularQuizzes();

  if (queryQuizId.value) {
    fetchQuizDetails(false, '');
  }
});
</script>

<style lang="sass" scoped>
.quiz-bar
  border: 2px solid var(--ion-color-primary)
  border-radius: 12px
.count-badge
  background: #5a188f
  font-weight: bolder
  border-radius: 20px
.ticket-badge
  background: var(--ion-color-primary)
  color: white
  border-top-right-radius: 20px
  border-bottom-right-radius: 20px
  height: 32px

.user-prof
  width: 35px
  height: 35px
  min-width: 35px
  border-radius: 6px
  background-size: cover
  background-position: center

.ticket-svg
  height: 56px
  width: 56px
  margin-top: -12px
  margin-right: -14px
.token-field
  width: 155px
  height: 35px
  border-radius: 10px
.token-txt
  position: absolute
  color: black
  margin-left: 10px
  margin-top: 3px
  font-weight: bolder
  font-size: 16px
.title
  font-size: 24px
  font-weight: bold
.quiz-history
  color: var(--ion-color-primary)
  text-decoration: underline
  font-size: 14px
.dark .quiz-history
  color: var(--ion-color-primary) !important
</style>
