<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="title mt-3">{{ sectionTitle }}</div>
    </div>

    <div v-if="isQuizLoader" class="d-flex">
      <ion-skeleton-text :animated="true" class="quiz-card ml-1" />
      <ion-skeleton-text :animated="true" class="quiz-card ml-1" />
      <ion-skeleton-text :animated="true" class="quiz-card ml-1" />
    </div>
    <div v-else-if="!isEmpty(quizzes) && !isQuizLoader" class="d-flex pb-3">
      <Grid :scrollSm="false" :lg="4" :md="3" :sm="2">
        <quizzes-card
          v-for="(quiz, index) in quizzes"
          :key="index"
          :is-continue="isContinue"
          :counts="counts"
          class="grid-item"
          :quiz="quiz"
          @open="openQuizModal"
          @continue="openContinueModal"
        />
      </Grid>
    </div>
    <div v-else class="no-data">No Ongoing Quizzes here yet!</div>
    <div class="d-flex align-items-center text-center justify-content-center">
      <div v-if="loading" class="d-flex">
        <ion-skeleton-text :animated="true" class="quiz-card new-quizzes-card ml-1" />
        <ion-skeleton-text :animated="true" class="quiz-card new-quizzes-card ml-1" />
        <ion-skeleton-text :animated="true" v-if="!isMobSmallScreen" class="quiz-card new-quizzes-card ml-1" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import QuizzesCard from '@/shared/components/QuizzesCard.vue';

const { isMobSmallScreen } = useWindowSize();
const props = defineProps({
  sectionTitle: {
    type: String,
    default: '',
  },
  isNew: {
    type: Boolean,
    default: false,
  },
  quizzes: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  counts: {
    type: Object,
    default: () => {},
  },
  nextPageExists: {
    type: Boolean,
    default: false,
  },
  isContinue: {
    type: Boolean,
    default: false,
  },
  isQuizLoader: {
    type: Boolean,
    default: false,
  },
});

const isContinue = toRef(props, 'isContinue');
const emits = defineEmits(['open', 'continue', 'loadMore']);
const openQuizModal = (value: any) => {
  emits('open', value);
};
const openContinueModal = (isContinueInsight: Boolean, quiz: any) => {
  emits('continue', isContinueInsight, quiz);
};
const requestLoadMore = () => {
  emits('loadMore');
};
</script>
<style lang="sass" scoped>
.new-quizzes-card
  width: 206px !important
  height: 200px !important
  @media(max-width:500px)
    width: 170px !important
.quiz-card
  width: 240px
  height: 230px
  border-radius: 10px
.spinner
  width: 30px
  height: 30px
.title
  font-size: 24px
  font-weight: bold
</style>
