<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-title class="title w-100">{{ truncateText(quiz.title, 78) }}</ion-title>
        </div>
        <ion-buttons slot="end">
          <ion-button class="close-button" @click="dismissModal('')">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="pb-3 px-4 mt-3">
        <div>Learn your OC's personality by answering questions every day!</div>
        <div class="mt-2 sub-title">{{ isEmpty(userCharacters) ? 'Create a Character' : 'Choose your character' }}</div>
        <div class="py-3">
          <SearchInput
            v-model="text"
            :search-icon="true"
            :shortcut-icon="false"
            :clear-icon="false"
            :hide-shortcut-icon-on-blur="true"
            :clear-on-esc="false"
            :blur-on-esc="true"
            :select-on-focus="false"
            :shortcut-listener-enabled="true"
            placeholder="Aa"
            @input="onSearch"
          />
        </div>
        <div>
          <quizzes-tabs :is-modal="true" class="mt-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
        </div>
        <div v-if="isloading" class="d-flex justify-content-center mt-2">
          <ChLoading size="sm" class="spinner" />
        </div>

        <Grid v-else-if="!isEmpty(userCharacters)" :scrollSm="false" :sm="2">
          <CharacterCard
            v-for="character in userCharacters"
            :key="`char-${character.id}`"
            class="grid-item"
            :character="character"
            :selected-char="selectedCharacterId === character.id"
            @click="isCharacterSelected(character)"
          />
        </Grid>
        <div v-else class="d-flex justify-content-center align-items-center text-center py-3">
          Get ready for quizzes by creating a character everyone can see.
          <span style="text-decoration: underline; color: var(--ion-color-primary)" class="ml-1">
            <router-link :to="{ name: 'character-creator' }" @click="dismissModal">Publish one now!</router-link>
          </span>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button
            :disabled="
              isEmpty(selectedCharacterId) ||
              (getCurrentDateTime < getNextDayDateTime && !isEmpty(selectedCharacter.quizzes))
            "
            class="choose-btn"
            @click="chooseChar"
          >
            <vue-countdown
              v-if="!isEmpty(selectedCharacter)"
              v-slot="{ hours, minutes, seconds }"
              :time="formatFromNowInMilliseconds(get(selectedCharacter, 'quizzes[0].next_day_start_date'))"
            >
              <span v-if="isEmpty(selectedCharacter.quizzes)">Choose</span>

              <span v-else-if="getCurrentDateTime >= getNextDayDateTime"> Continue</span>

              <span v-else
                >Continue <span v-if="hours || minutes || seconds">in</span> <span v-if="hours">{{ hours }}h</span
                ><span v-if="minutes">{{ minutes }}m</span
                ><span v-if="seconds && seconds >= 0" class="ml-1">{{ seconds < 10 ? `0${seconds}` : seconds }}s</span>
              </span>
            </vue-countdown>
            <span v-else>Choose</span>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import { truncateText } from '@/shared/utils/string';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/QuizzesCharacterCard.vue';
import QuizzesTabs from '@/shared/pages/quizzes/components/quizzes-tabs.vue';
import { Character, Tab } from '@/shared/types/static-types';
import { quizzesCharSelectionTabs } from '@/shared/statics/tabs';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { getQuizCharacters } from '@/shared/actions/quizzes';

const text = ref('');
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  quiz: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(['close', 'selected', 'continue']);
const currentTab = ref('allchar');
const tabs = ref(quizzesCharSelectionTabs);
const tabIndex = ref(0);
const isOpen = toRef(props, 'isOpen');
const quiz = toRef(props, 'quiz');
const userCharacters = ref([]) as Ref<Character[]>;
const selectedCharacterId: any = ref('');
const selectedCharacter: any = ref({});
const isloading = ref(false);
const router = useRouter();
const currentTime = ref(new Date());

const updateTime = () => {
  currentTime.value = new Date();
};
const timeInterval = setInterval(updateTime, 1000);

watch(isOpen, () => {
  isOpen.value ? fetchUserCharacters() : clearInterval(timeInterval);
});

const getNextDayDateTime = computed(() => {
  const { getDateTime } = useCurrentTime(new Date(get(selectedCharacter.value, 'quizzes[0].next_day_start_date')));
  return getDateTime.value;
});

const getCurrentDateTime = computed(() => {
  const { getDateTime } = useCurrentTime(currentTime.value);
  return getDateTime.value;
});

const isCharacterSelected = (character: Character) => {
  selectedCharacter.value = character;
  return (selectedCharacterId.value = character.id);
};

const fetchUserCharacters = async () => {
  isloading.value = true;
  userCharacters.value = !isEmpty(text.value)
    ? await getQuizCharacters({ quiz_id: quiz.value.id, search: text.value })
    : await getQuizCharacters({ quiz_id: quiz.value.id });
  isloading.value = false;
};
const dismissModal = (val: any) => {
  resetData();
  emits('close', val);
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
};

const resetData = () => {
  selectedCharacterId.value = '';
  selectedCharacter.value = {};
};
const onSearch = () => {
  searchTextChanged();
};

const chooseChar = () => {
  if (!isEmpty(selectedCharacter.value.quizzes)) {
    router.push({
      name: 'quizzes',
      query: {
        quizId: selectedCharacter.value.quizzes[0].quiz,
        logId: selectedCharacter.value.quizzes[0].id,
        charId: selectedCharacter.value.id,
      },
    });
  }

  isEmpty(selectedCharacter.value.quizzes)
    ? emits('selected', selectedCharacter.value)
    : emits('continue', selectedCharacter.value);
  resetData();
};

const searchTextChanged = debounce(async () => {
  await fetchUserCharacters();
}, 100);
</script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important
.choose-btn
  --background: #00b4c5
  --border-radius: 11px
  text-transform: none
  color: white
.dark .choose-btn
  color: white
.choose-btn::part(native)
  color: white !important
.spinner
  width: 30px
  height: 30px

.quiz-content
  height: calc(100vh - 330px) !important
  overflow: auto
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: calc(100dvh - 142px)
  overflow: auto
  overflow-x: hidden
  @media(max-width:550px)
    max-height: calc(100dvh - 250px)

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 16px
  font-weight: bold
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  padding-left: 55px
  padding-right: 0px
</style>
