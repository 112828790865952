<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-title class="title">No More Tickets :( </ion-title>
        </div>
        <ion-buttons slot="end">
          <ion-button class="close-button" @click="dismissModal()">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="px-3 py-2 mt-1">
        <div class="px-3 py-3 d-flex justify-content-center align-items-center token-bg">
          <div>
            <inline-svg src="/ticket-with-border.svg" class="ticket-svg" />
          </div>

          <div class="d-flex flex-column mx-auto align-items-center text-center">
            <div class="d-flex py-1 ticket-badge">
              <div class="px-3 mx-auto d-flex align-items-center text-center count-badge">
                <span>{{ counts.quiz_count_available }}</span
                >/{{ counts.max_allowed_quiz }}
              </div>
            </div>
            <div style="font-size: 22px; font-weight: bolder" class="text-black mt-2" v-if="subscriptionLevel < 2">
              You've taken all the quizzes you can for now!
              <ion-button
                color="tertiary"
                size="large"
                class="br mt-2 mb-n1 d-flex flex-column align-items-center justify-content-center"
                @click="openSubscribeModal($event, 'noMoreQuizTokensModal')"
              >
                <span v-if="subscriptionLevel === 0">Get more tickets with</span>
                <span v-if="subscriptionLevel === 1">Get unlimited tickets with</span>
                <PlusTag size="md" class="ml-2" :ultra="subscriptionLevel === 1" />
              </ion-button>
            </div>
          </div>
        </div>
        <div class="py-2 px-2 text-black">
          <div style="font-weight: bolder">Time to next quiz:</div>
          <div style="border: 2px solid; border-radius: 7px" class="px-2 py-2 mt-2">
            <div class="d-flex px-2 justify-content-center align-items-center text-center">
              <i class="ti-time time-icon" />
              <vue-countdown
                v-slot="{ hours, minutes, seconds }"
                :time="formatFromNowInMilliseconds(counts.time_difference)"
              >
                <div class="d-flex flex-column" :class="isMobSmallScreen ? 'mx-2' : 'mx-3'">
                  <div class="d-flex timer">
                    <span class="ml-1">{{ hours < 10 ? `0${hours}` : hours }} </span>
                    <span>:</span>
                    <span> {{ minutes < 10 ? `0${minutes}` : minutes }}</span>
                    <span>:</span>
                    <span> {{ seconds < 10 ? `0${seconds}` : seconds }}</span>
                  </div>
                  <div class="d-flex time-units">
                    <span style="margin-left: -2px">HOURS</span>
                    <span> MIN</span>

                    <span style="margin-left: 15px"> SEC</span>
                  </div>
                </div>
              </vue-countdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="dismissModal">Close</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';

import { openSubscribeModal } from '@/shared/utils/modals';
import PlusTag from '@/shared/components/PlusTag.vue';
import { authStore } from '@/shared/pinia-store/auth';
const { subscriptionLevel } = authStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  counts: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(['close', 'view']);
const isOpen = toRef(props, 'isOpen');
const counts = toRef(props, 'counts');

const dismissModal = () => {
  emits('close');
};
const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 300;
});
</script>
<style lang="sass" scoped>
.time-icon
  font-size: 45px
  @media(max-width:300px)
    font-size: 35px
.timer
  gap: 15px
  font-size: 26px
  color: var(--ion-color-primary)
  @media(max-width:290px)
    gap: 10px
  @media(max-width:270px)
    font-size: 22px
.time-units
  gap: 20px
  font-weight: bolder
  @media(max-width:290px) and (min-width:270px)
    gap: 10px
  @media(max-width:270px)
    gap: 5px
.count-badge
  background: #5a188f
  font-weight: bolder
  border-radius: 20px
.ticket-badge
  background: var(--ion-color-primary)
  border-radius: 20px
  height: 35px
  width: 230px
  color: white
  @media(max-width:500px)
    width: 100% !important

.br
  --border-radius: 20px

.ticket-svg
  height: 115px
  width: 125px
  @media(max-width:300px)
    width: 108px

.token-bg
  background: white
  border-radius: 7px
.dark .token-bg
  background: #010123 !important
.token-txt
  position: absolute
  color: black
  top: 88px
  font-weight: bolder
  font-size: 22px
  left: 42px
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: #e6e6e6
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 18px
  font-weight: bold
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  padding-left: 55px
  padding-right: 0px
.ans-border
  border: 2px solid gray
  border-radius: 7px
</style>
