<template>
  <div ref="button" class="ch-button">
    <ion-button @click="click" :disabled="disabled" :class="{ [`r-${radius}`]: radius }">
      <slot v-if="!loading && ready" />
      <ChLoading size="sm" v-else-if="loading && ready" />
      <div class="message successMessage">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11">
          <polyline stroke="currentColor" points="1.4,5.8 5.1,9.5 11.6,2.1 " />
        </svg>
        <span class="button-text">Success</span>
      </div>
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
const emits = defineEmits(['click']);

const click = (e: any) => {
  emits('click', e);
};

const props = defineProps({
  radius: {
    type: String,
  },
  loading: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  showSuccess: {
    type: Boolean,
    default: false,
  },
  showBigSuccess: {
    type: Boolean,
    default: false,
  },
  bigSuccessMessage: {
    type: String,
    default: false,
  },
});

const loading = toRef(props, 'loading');
const ready = ref(true);
const showSuccess = toRef(props, 'showSuccess');
const showBigSuccess = toRef(props, 'showBigSuccess');
const bigSuccessMessage = toRef(props, 'bigSuccessMessage');

const button = ref<any>(null);

const { resizeCanvas, render: renderSm, initBurst: initBurstSm, clear } = useSmallConfetti();
const { render: renderBig, initBurst: initBurstBig } = useBigConfetti();

onMounted(() => {
  const textElements = document.querySelectorAll('.button-text');
  textElements.forEach((element: any) => {
    const characters = element.innerText.split('');
    let characterHTML = '';
    characters.forEach((letter: any, index: any) => {
      characterHTML += `<span class="char${index}" style="--d:${index * 30}ms; --dr:${
        (characters.length - index - 1) * 30
      }ms;">${letter}</span>`;
    });
    element.innerHTML = characterHTML;
  });

  // resize listenter
  window.addEventListener('resize', resizeCanvas);
});

onBeforeUnmount(() => {
  clear();
  window.removeEventListener('resize', resizeCanvas);
});

watch(loading, async (val: boolean, oldVal: boolean) => {
  try {
    if (!val && oldVal && showSuccess.value && !showBigSuccess.value) {
      renderSm();
      initBurstSm();
      ready.value = false;
      button.value.classList.add('complete');

      setTimeout(() => {
        try {
          button.value.classList.remove('complete');
          ready.value = true;
        } catch (error) {}
      }, 3000);
    }

    if (!val && oldVal && showBigSuccess.value) {
      renderBig();
      initBurstBig();

      ready.value = false;
      button.value.classList.add('complete');

      let alert: any;

      if (bigSuccessMessage.value) {
        alert = await alertController.create({
          cssClass: 'no-backdrop',
          mode: 'ios',
          message: bigSuccessMessage.value,
          buttons: ['Okay'],
          animated: true,
        });

        alert.present();
      }

      setTimeout(() => {
        try {
          button.value.classList.remove('complete');
          ready.value = true;

          if (alert) {
            alert.dismiss();
          }
        } catch (error) {}
      }, 3000);
    }
  } catch (error) {}
});
</script>

<style scoped lang="sass">
.ch-button
  ion-button
    min-width: 100px
    height: 32px
    --height: 32px
    margin: 0
    &.r-full
      --border-radius: 20px
    &.r-medium
      --border-radius: 10px
    text-transform: none !important

  ::v-deep
    .button-text span
      opacity: 0
      position: relative
    &.complete
      pointer-events: none !important
      .submitMessage svg
        top: -30px
        transition: none

    &.complete
      .submitMessage .button-text span
        top: -8px
        transition: none

    &.complete
      .loadingMessage
        top: 80px

    &.complete
      .successMessage .button-text span
        left: 0
        opacity: 1
        transition: all 0.2s ease calc(var(--d) + 1000ms)

    &.complete
      .successMessage svg
        stroke-dashoffset: 0
        transition: stroke-dashoffset .3s ease-in-out 1.4s

    .message
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      width: 100%

    .message svg
      display: inline-block
      fill: none
      margin-right: 5px
      stroke-linecap: round
      stroke-linejoin: round
      stroke-width: 2

    .successMessage .button-text span
      left: 5px
      transition: all 0.2s ease var(--dr)

    .successMessage svg
      color: #5cffa1
      stroke-dasharray: 20
      stroke-dashoffset: 20
      transition: stroke-dashoffset .3s ease-in-out
      width: 14px
</style>
