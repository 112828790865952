export const useSmallConfetti = () => {
  const canvasEl = ref<any>(null);
  const rendererID = ref<number>();

  const clear = () => {
    const { ctx } = init();

    ctx.clearRect(0, 0, ctx.width, ctx.height);

    window.cancelAnimationFrame(rendererID.value || 0);
  };

  const init = () => {
    try {
      if (!canvasEl.value) {
        canvasEl.value = document.getElementById('animations');

        if (!canvasEl.value) return { cx: 0, cy: 0, ctx: undefined };
      }

      const ctx = canvasEl.value.getContext('2d');
      canvasEl.value.width = window.innerWidth;
      canvasEl.value.height = window.innerHeight;
      const cx = ctx.canvas.width / 2;
      const cy = ctx.canvas.height / 2;

      return { cx, cy, ctx };
    } catch (error) {}
  };

  let confetti: any[] = [];
  let sequins: any[] = [];

  const confettiCount = 20;
  const sequinCount = 10;
  const gravityConfetti = 0.3;
  const gravitySequins = 0.55;
  const dragConfetti = 0.075;
  const dragSequins = 0.02;
  const terminalVelocity = 3;

  const colors = [
    { front: 'red', back: 'darkred' },
    { front: 'green', back: 'darkgreen' },
    { front: 'blue', back: 'darkblue' },
    { front: 'yellow', back: 'darkyellow' },
    { front: 'orange', back: 'darkorange' },
    { front: 'pink', back: 'darkpink' },
    { front: 'purple', back: 'darkpurple' },
    { front: 'turquoise', back: 'darkturquoise' },
  ];

  const randomRange = (min: number, max: number) => Math.random() * (max - min) + min;

  const initBurst = () => {
    init();
    // Sequin Class
    function Sequin() {
      // @ts-ignore
      (this.color = colors[Math.floor(randomRange(0, colors.length))].back),
        // @ts-ignore
        (this.radius = randomRange(1, 2)),
        // @ts-ignore
        (this.position = {
          x: randomRange(canvasEl.value.width / 2 - 100 / 3, canvasEl.value.width / 2 + 100 / 3),
          y: randomRange(canvasEl.value.height / 2 + 32 / 2 + 8, canvasEl.value.height / 2 + 1.5 * 32 - 8),
        }),
        // @ts-ignore
        (this.velocity = {
          x: randomRange(-6, 6),
          y: randomRange(-8, -12),
        });
    }

    function Confetto() {
      init();

      // @ts-ignore
      this.randomModifier = randomRange(0, 99);
      // @ts-ignore
      this.color = colors[Math.floor(randomRange(0, colors.length))];
      // @ts-ignore
      this.dimensions = {
        x: randomRange(5, 9),
        y: randomRange(8, 15),
      };
      // @ts-ignore
      this.position = {
        x: randomRange(canvasEl.value.width / 2 - 100 / 4, canvasEl.value.width / 2 + 100 / 4),
        y: randomRange(canvasEl.value.height / 2 + 32 / 2 + 8, canvasEl.value.height / 2 + 1.5 * 32 - 8),
      };
      // @ts-ignore
      this.rotation = randomRange(0, 2 * Math.PI);

      // @ts-ignore
      this.scale = {
        x: 1,
        y: 1,
      };
      // @ts-ignore
      this.velocity = initConfettoVelocity([-9, 9], [6, 11]);
    }

    const initConfettoVelocity = (xRange: any[], yRange: any[]) => {
      const x = randomRange(xRange[0], xRange[1]);
      const range = yRange[1] - yRange[0] + 1;
      let y = yRange[1] - Math.abs(randomRange(0, range) + randomRange(0, range) - range);
      if (y >= yRange[1] - 1) {
        y += Math.random() < 0.25 ? randomRange(1, 3) : 0;
      }
      return { x: x, y: -y };
    };

    Confetto.prototype.update = function () {
      this.velocity.x -= this.velocity.x * dragConfetti;
      this.velocity.y = Math.min(this.velocity.y + gravityConfetti, terminalVelocity);
      this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();
      this.position.x += this.velocity.x;
      this.position.y += this.velocity.y;
      this.scale.y = Math.cos((this.position.y + this.randomModifier) * 0.09);
    };

    Sequin.prototype.update = function () {
      this.velocity.x -= this.velocity.x * dragSequins;
      this.velocity.y = this.velocity.y + gravitySequins;
      this.position.x += this.velocity.x;
      this.position.y += this.velocity.y;
    };

    for (let i = 0; i < confettiCount; i++) {
      //@ts-ignore
      confetti.push(new Confetto());
    }
    for (let i = 0; i < sequinCount; i++) {
      //@ts-ignore
      sequins.push(new Sequin());
    }
  };

  const render = () => {
    init();

    const ctx = canvasEl.value.getContext('2d');

    canvasEl.value.width = window.innerWidth;
    canvasEl.value.height = window.innerHeight;

    let cx = ctx.canvas.width / 2;
    let cy = ctx.canvas.height / 2;

    ctx.clearRect(0, 0, canvasEl.value.width, canvasEl.value.height);

    confetti.forEach((confetto, index) => {
      let width = confetto.dimensions.x * confetto.scale.x;
      let height = confetto.dimensions.y * confetto.scale.y;

      ctx.translate(confetto.position.x, confetto.position.y);
      ctx.rotate(confetto.rotation);

      confetto.update();

      ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

      ctx.fillRect(-width / 2, -height / 2, width, height);

      ctx.setTransform(1, 0, 0, 1, 0, 0);

      if (confetto.velocity.y < 0) {
        ctx.clearRect(canvasEl.value.width / 2 - 100 / 2, canvasEl.value.height / 2 + 25 / 2, 100, 35);
      }
    });

    sequins.forEach((sequin, index) => {
      ctx.translate(sequin.position.x, sequin.position.y);
      sequin.update();
      ctx.fillStyle = sequin.color;
      ctx.beginPath();
      ctx.arc(0, 0, sequin.radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      if (sequin.velocity.y < 0) {
        ctx.clearRect(canvasEl.value.width / 2 - 100 / 2, canvasEl.value.height / 2 + 35 / 2, 100, 35);
      }
    });

    confetti.forEach((confetto, index) => {
      if (confetto.position.y >= canvasEl.value.height) confetti.splice(index, 1);
    });
    sequins.forEach((sequin, index) => {
      if (sequin.position.y >= canvasEl.value.height) sequins.splice(index, 1);
    });

    rendererID.value = window.requestAnimationFrame(render);
  };

  const resizeCanvas = () => init();

  return { initBurst, resizeCanvas, render, canvasEl, clear };
};
