<template>
  <div class="px-2 pt-3 pb-2">
    <div class="d-flex align-items-center justify-content-center text-center flex-column">
      <span>Each quiz attempt takes a ticket. Tickets regenerate over time!</span>
      <ion-button color="tertiary" size="small" class="clickable-item-hov mt-2 mb-n1 d-flex flex-column align-items-center justify-content-center" @click="openSubscribeModal($event, 'quizCountPopover')">
        <span v-if="subscriptionLevel === 1">Unlimited tickets with</span>
        <span v-else>Get more tickets with</span>
        <PlusTag size="md" class="ml-1" :ultra="subscriptionLevel === 1"/>
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { openSubscribeModal } from '@/shared/utils/modals';
import PlusTag from '@/shared/components/PlusTag.vue'
import { authStore } from '@/shared/pinia-store/auth';
const { subscriptionLevel } = authStore();
</script>

<style scoped lang="sass"></style>
