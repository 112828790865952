<template>
  <div class="character-card d-flex position-relative" :class="{ active: selectedChar, 'not-active': !selectedChar }">
    <div class="position-absolute d-flex flex-column justify-content-end char-info">
      <div class="name">
        <span v-if="character.privacy === 'M'">🔒&nbsp;</span><span v-if="character.privacy === 'U'">🔗&nbsp;</span>
        <span> {{ truncateText(character.info.name, 30) }}</span>
      </div>
    </div>
    <div class="image d-flex align-items-center justify-content-center">
      <img loading="lazy" v-character-card-image :src="get(character, 'info.cropProfilePicture')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Character } from '@/shared/types/static-types';
import { truncateText } from '@/shared/utils/string';

const props = defineProps({
  character: {
    type: Object,
    default: () => {},
  },
  selectedChar: {
    type: Boolean,
    default: false,
  },
});

const character = computed(() => props.character as Character);
</script>

<style lang="sass" scoped>
.active
  border: 4px solid var(--ion-color-primary)
.not-active
  border: none !important
.character-card
  width: 100%
  aspect-ratio: 1
  border-radius: 15px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  .image
    background: #4A9FC3
    width: 100%
    height: 100%
    border-radius: 10px

  img
    width: 100%
    height: 100%
    border-radius: 10px
    object-fit: cover
  .name
    font-size: 16px
    font-weight: bold
    color: white
.char-info
  border-radius: 10px
  width: 100%
  height: 100%
  padding: 9px
  background: linear-gradient(180deg, rgba(247,6,207,0) 0%, rgba(0,0,0,0.3) 74%, #170624CF 100%)
</style>
